<template>
  <b-card-code>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

      <div class="custom-search d-flex justify-content-start">
          <vue-excel-xlsx
              :data="getData"
              :columns="columns10"
              :filename="'filename'"
              :sheetname="'subjects'"
          >
              <b>Excel</b>
          </vue-excel-xlsx>
          <vue-json-to-csv :json-data="getData"
                           :csv-title="'My_CSV'"
          >
              <button>
                  <b>CSV</b>
              </button>
          </vue-json-to-csv>
          <button @click="pdfGenerate()">
              <b>PDF</b>
          </button>
      </div>
    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="parent_list"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <span v-else-if="props.column.field === 'student_ids' && props.row.students">
          <span v-for="(std,key2) in props.row.students" :key="key2">{{std.student_id}}<br></span>
        </span>
        <span v-else-if="props.column.field === 'cadet_ids' && props.row.students">
          <span v-for="(std,key2) in props.row.students" :key="key2">{{std.cadet_no}}<br></span>
        </span>
        <span v-else-if="props.column.field === 'father_image'">
          <template>
            <img :src="props.row.father_image?imgShow(props.row.father_image):'/student_face.gif'" width="50" height="50" alt="">
          </template>
        </span>
        <span v-else-if="props.column.field === 'mother_image'">
          <template>
            <img :src="props.row.mother_image?imgShow(props.row.mother_image):'/female_face.png'" width="50" height="50" alt="">
          </template>
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'is_active'">
          <b-form-checkbox v-if="$can('read','Parent')" :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
            <b-badge v-else :variant="statusVariant(props.row.is_active)">
              {{ props.row.is_active?'Active':'Deactivated' }}
            </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
                <!-- modal login button -->
              <b-button v-if="$can('read','Parent')" @click="selectedRow(props.row)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-login
                  variant="primary" >
                <feather-icon icon="Edit2Icon" class="mr-50"/>
              </b-button>
            </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['5','10','15']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRecords"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal" size="lg"
        hide-footer
        :title="model_mode==='add'?'Add New':'Edit Information'"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form ref="parent_update">
          <b-row>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Father Name') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('father name')"
                  rules="required"
              >
                <b-form-input name="father_name" v-model="father_name"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write father name')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Father Phone Number') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('father phone number')"
                  rules=""
              >
                <b-form-input name="father_phone_number" v-model="father_phone_number"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write father phone number')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <!-- <b-col md="4">
              <b-card-text>
                <span>{{ $t('Father Email') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('father email')"
                  rules="required|email"
              >
                <b-form-input name="father_email" v-model="father_email"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write father email')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col> -->
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Father Profession') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('father profession')"
                  rules=""
              >
                <b-form-input name="father_profession" v-model="father_profession"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write father profession')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Father NID Number') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('father nid number')"
                  rules=""
              >
                <b-form-input type="number" name="father_nid_no" v-model="father_nid_no"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write NID number')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Father Photo') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('father photo')"
                  rules=""
              >
                <b-form-file name="father_image" v-model="father_image" accept="image/png|image/jpeg|image/jpg"
                             :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                             :placeholder="$t('Select an image')"
                             class="form-control">
                </b-form-file>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Mother Name') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('mother name')"
                  rules="required"
              >
                <b-form-input name="mother_name" v-model="mother_name"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write mother name')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Mother Phone Number') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('mother phone number')"
                  rules=""
              >
                <b-form-input name="mother_phone_number" v-model="mother_phone_number"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write mother phone number')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Mother Profession') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('mother profession')"
                  rules=""
              >
                <b-form-input name="mother_profession" v-model="mother_profession"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write mother profession')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Mother NID Number') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('mother nid number')"
                  rules=""
              >
                <b-form-input type="number" name="mother_nid_no" v-model="mother_nid_no"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write mother NID number')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Mother Photo') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('mother photo')"
                  rules=""
              >
                <b-form-file name="mother_image" v-model="mother_image" accept="image/png|image/jpeg|image/jpg"
                             :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                             :placeholder="$t('Select an image')"
                             class="form-control">
                </b-form-file>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Present Address') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('present address')"
                  rules=""
              >
                <textarea name="present_address" v-model="present_address"
                          class="form-control"  id="present_address"
                          :placeholder="$t('write present address here')"></textarea>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Permanent Address') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('permanent address')"
                  rules=""
              >
                <textarea name="permanent_address" v-model="permanent_address"
                          class="form-control"  id="permanent_address"
                          :placeholder="$t('write permanent address here')"></textarea>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Local guardian Name') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('local guardian name')"
                  rules=""
              >
                <b-form-input name="local_guardian_name" v-model="local_guardian_name"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write local guardian name')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Local guardian Contact Number') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('local guardian contact number')"
                  rules=""
              >
                <b-form-input name="local_guardian_phone_number" v-model="local_guardian_phone_number"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write local guardian contact number')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Local guardian Address') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('local guardian address')"
                  rules=""
              >
                <textarea name="local_guardian_address" id="local_guardian_address" v-model="local_guardian_address"
                          class="form-control" :placeholder="$t('write address here')"></textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Relation with student') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('relation with student')"
                  rules=""
              >
                <b-form-input name="relation" v-model="relation"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write relation with student')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button class="mt-3"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BBadge, BPagination, BFormGroup, BFormInput, BFormSelect,
  BModal, BForm,BRow, BCol,BFormCheckbox,BFormFile,BCardText,
} from 'bootstrap-vue'
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
//import {mapGetters} from "vuex";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";

Vue.use(VueExcelXlsx);
export default {
  name:'StudentParent',
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,BFormCheckbox,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BModal,BFormFile,BCardText,
    BForm,BRow, BCol,
    ValidationProvider,
    ValidationObserver, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      relation:'',
      father_name:'',
      father_email:'',
      father_phone_number:'',
      father_profession:'',
      father_nid_no:'',
      father_image:null,
      mother_name:'',
      mother_phone_number:'',
      mother_profession:'',
      mother_nid_no:'',
      mother_image:null,
      present_address:'',
      permanent_address:'',
      local_guardian_address:'',
      local_guardian_name:'',
      local_guardian_phone_number:'',
      required,

      parent_list:[],
      pageLength:10,
      totalRecords: 0,
      currentPage:1,
      nextPage: 1,

      dir: false,
      model_mode:'add',
      selected_row:{},
      columns: [
        {
          label: 'SL #',
          field: 'id',
        },
        {
          label: 'Student Number',
          field: 'cadet_ids',
        },
        {
          label: 'Father Image',
          field: 'father_image',
        },
        {
          label: 'Father Name',
          field: 'father_name',
        },
        // {
        //   label: 'Father Email',
        //   field: 'father_email',
        // },
        {
          label: 'Father Phone No.',
          field: 'father_phone_number',
        },
        {
          label: 'Father Profession',
          field: 'father_profession',
        },
        // {
        //   label: 'F Monthly Income',
        //   field: 'father_monthly_income',
        // },
        {
          label: 'Mother Image',
          field: 'mother_image',
        },
        {
          label: 'Mother Name',
          field: 'mother_name',
        },
        // {
        //   label: 'Mother Email',
        //   field: 'mother_email',
        // },
        {
          label: 'Mother Phone No.',
          field: 'mother_phone_number',
        },
        {
          label: 'Mother Profession',
          field: 'mother_profession',
        },
        // {
        //   label: 'M Monthly Income',
        //   field: 'mother_monthly_income',
        // },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      status: [{
        1: 'active',
        2: 'deactivate',
      },
        {
          1: 'light-primary',
          2: 'light-danger',
        }],
        columns10: [
            {
                label: 'Father Name',
                field: 'FatherName',
            },
            {
                label: 'Father Email',
                field: 'FatherEmail',
            },
            {
                label: 'Father Profession',
                field: 'FatherProfession',
            },
            {
                label: 'FatherMonthlyIncome',
                field: 'FatherMonthlyIncome',
            },
            {
                label: 'Father Phone',
                field: 'FatherPhone',
            },
            {
                label: 'Father NID',
                field: 'FatherNID',
            },
            {
                label: 'Mother Name',
                field: 'MotherName',
            },
            {
                label: 'Mother Email',
                field: 'MotherEmail',
            },
            {
                label: 'Mother Profession',
                field: 'MotherProfession',
            },
            {
                label: 'MotherMonthlyIncome',
                field: 'MotherMonthlyIncome',
            },
            {
                label: 'Mother Phone',
                field: 'MotherPhone',
            },{
                label: 'Mother NID',
                field: 'MotherNID',
            },
            {
                label: 'Permanent Address',
                field: 'PermanentAddress',
            },{
                label: 'Present Address',
                field: 'PresentAddress',
            },
            {
                label: 'Status',
                field: 'Status',
            },
        ],
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.nextPage = value;
        this.getParentList();
      },
    },
  },
  methods:{
    async getParentList(){
        await apiCall.get('/get/parent/list/by/page?per_page='+this.pageLength +'&page='+ this.nextPage).then((response)=>{
          this.parent_list=response.data.data;
          this.totalRecords = response.data.total;
        }).catch(()=>{
          this.parent_list=[];
        })
      },

    imgShow(path) {
      return process.env.VUE_APP_ENV_RESOURCHURL + path;
    },
    selectedRow(row){
      this.selected_row=row;
      this.relation=row.relation;
      this.father_name=row.father_name;
      this.father_email=row.father_email;
      this.father_phone_number=row.father_phone_number;
      this.father_profession=row.father_profession;
      this.father_nid_no=row.father_nid_no;
      this.father_image=null;
      this.mother_name=row.mother_name;
      this.mother_phone_number=row.mother_phone_number;
      this.mother_profession=row.mother_profession;
      this.mother_nid_no=row.mother_nid_no;
      this.mother_image=null;
      this.present_address=row.present_address;
      this.permanent_address=row.permanent_address;
      this.local_guardian_address=row.local_guardian_address;
      this.local_guardian_name=row.local_guardian_name;
      this.local_guardian_phone_number=row.local_guardian_phone_number;
      this.model_mode='edit';
      this.$refs['my-modal'].show()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.parent_update);
          apiCall.post(`/student/parent/information/update${this.selected_row.id}`,data).then((response)=>{
            this.hideModal();
            this.$toaster.success(response.data.message);
            //this.$store.dispatch('GET_ALL_STUDENT_PARENT');
            this.getParentList();
          }).catch((error)=>{
            if (error.response.status ===422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            }
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    statusChange(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to change status!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/student/parent/status/change${id}`).then((response)=>{
            this.$toaster.success( response.data.message);
           // this.$store.dispatch('GET_ALL_STUDENT_PARENT');
           this.getParentList();
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
            //this.$store.dispatch('GET_ALL_STUDENT_PARENT');
            this.getParentList();
          });
        }else{
         // this.$store.dispatch('GET_ALL_STUDENT_PARENT');
         this.getParentList();
        }
      })
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
      async pdfGenerate(){
          /*only change able*/
          let title="Student Parents";
          let clm=['FatherName','FatherEmail','FatherProfession','FatherMonthlyIncome','FatherPhone', 'FatherNID','MotherName', 'MotherEmail', 'MotherProfession','MotherMonthlyIncome', 'MotherPhone','MotherNID','PermanentAddress','PresentAddress', 'Status'];
          /*change able end*/
          let data =new FormData();
          data.append('title',title);
          data.append('columns',JSON.stringify(clm));
          data.append('data',JSON.stringify(this.getData));
          await apiCall.post('/get/common/table/export/pdf',data,{responseType: 'blob'}).then((response)=>{
              const content = response.headers['content-type'];
              download(response.data,'subject.pdf', content)
          }).catch((error) => {
              this.$toaster.error(error.response.data.message);
          });
      },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    // ...mapGetters(['student_parents']),
      getData(){
          return this.parent_list.map(item => ({
              FatherName: item.father_name ?item.father_name:'',
              FatherEmail:item.father_email ? item.father_email : '',
              FatherProfession:item.father_profession ? item.father_profession: '',
              FatherMonthlyIncome:item.father_monthly_income ? item.father_monthly_income: '',
              FatherPhone: item.mother_phone_number ? item.mother_phone_number : '',
              FatherNID: item.father_nid_no ? item.father_nid_no : '',
              MotherName: item.mother_name ? item.mother_name : '',
              MotherEmail:item.mother_email ? item.mother_email : '',
              MotherProfession:item.mother_profession ? item.mother_profession : '',
              MotherMonthlyIncome:item.mother_monthly_income ? item.mother_monthly_income : '',
              MotherPhone: item.mother_phone_number ? item.mother_phone_number : '',
              MotherNID: item.mother_nid_no ? item.mother_nid_no : '',
              PermanentAddress: item.permanent_address ? item.permanent_address : '',
              PresentAddress: item.present_address ? item.present_address : '',
              Status: item.is_active ? 'Active' : 'Inactive',
          }));
      },
  },
  created() {
    //this.$store.dispatch('GET_ALL_STUDENT_PARENT');
    this.getParentList();
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
